import { React, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// Components
import Message from '../components/Message';
import Footer from '../components/Footer';
import Navbar from '../components/navbar/Navbar';

// Styles
import '../styles/contact.css';

function Login() {
  const navigate = useNavigate();

  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("Informazioni");

  const [pageMessage, setPageMessage] = useState("");

  const handleSubmission = async () => {
    if(message !== '' && email !== '' && name !== '' && subject !== '' && email.includes('@')) {
      axios.post('https://api.mylondoncorner.com/contact', {
        subject: subject,
        name: name,
        email: email,
        message: message
      }, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY
        }
      })
      .then((response) => {
        if(response.status === 200) {
          navigate('/contact/received')
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
    } else {
      if(!email.includes('@')) {
        setPageMessage({message: "E-mail non valida!", type: "error"});
      } else {
        setPageMessage({message: "Tutti i campi devono essere compilati!", type: "error"});
      }
    }
  };

  return (
    <>
      <Navbar />
        <br /><br />
        <div className='contact-container'>
          <h1 style={{ color: '#00247D' }}>Ciao! Come possiamo aiutarti?</h1>
          <br />
          {pageMessage && (
            <>
              <Message message={pageMessage.message} type={pageMessage.type} />
            </>
          )}
          <br />
          <h3 style={{ color: '#00247D' }}>Scrivici un messaggio</h3>
          <br />
          <select onChange={(e) => setSubject(e.target.value)} className='contact-message'>
            <option>Informazioni</option>
            <option>Supporto</option>
            <option>Collaborazione</option>
          </select>
          <br /><br />
          <textarea id="message" name="message" onChange={(e) => setMessage(e.target.value)} rows={8} className='contact-message' placeholder='Qualche domanda? Richiesta particolare?' />
          <br /><br />
          <input type='email' id="email" name="email" onChange={(e) => setEmail(e.target.value)} className='contact-message' placeholder='Inserisci la tua e-mail' />
          <br /><br />
          <input type='text' id="name" name="name" onChange={(e) => setName(e.target.value)} className='contact-message' placeholder='Inserisci il tuo nome' />
          <br /><br />
          <button className='button' onClick={handleSubmission}>Invia</button>
        </div>
      <Footer />
    </>
  );
}

export default Login;