import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

// Components
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/Footer';
import TourAvailabilityCalendar from '../../components/tour/TourAvailabilityCalendar';

// Styles
import '../../styles/tour/OurTours.css';

function OurTours() {
  const navigate = useNavigate();

  const [toursData, setToursData] = useState([]);
  const [loadingTours, setLoadingTours] = useState(true);

  useEffect(() => {
    setLoadingTours(true);
    axios.get(`https://api.mylondoncorner.com/tours/purchasable`, {
      headers: {
        'X-API-KEY': process.env.REACT_APP_API_KEY // Include the API key in the request headers
      }
    })
    .then((response) => {
      setToursData(response.data.tours);
      setLoadingTours(false);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      setLoadingTours(false);
    });
  }, []);

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>Tour di Londra con guida italiana - mylondoncorner</title>‍
        <meta name="description" content="Scopri Londra in modo completo con i nostri tour guidati in italiano. Esplora luoghi nascosti e attrazioni imperdibili!" />
        <link rel="canonical" href="https://mylondoncorner.com/tour-di-londra" />
        <meta name="twitter:card" content="https://api.mylondoncorner.com/images/logo-white.png" />        
        <meta name="twitter:site" content="@mylondoncorner" />        
        <meta name="twitter:creator" content="@mylondoncorner" />        
        <meta name="twitter:title" content="Tour di Londra con guida italiana - mylondoncorner" />        
        <meta name="twitter:description" content="Scopri Londra in modo completo con i nostri tour guidati in italiano. Esplora luoghi nascosti e attrazioni imperdibili!" />        
        <meta name="twitter:image" content="https://api.mylondoncorner.com/images/logo-white.png" />        
        <meta property="og:title" content="Tour di Londra con guida italiana - mylondoncorner" />        
        <meta property="og:description" content="Scopri Londra in modo completo con i nostri tour guidati in italiano. Esplora luoghi nascosti e attrazioni imperdibili!" />
        <meta property="og:image" content="https://api.mylondoncorner.com/images/logo-white.png" />
        <meta property="og:url" content="https://mylondoncorner.com/tour-di-londra" />
        <meta property="og:site_name" content="Il tuo viaggio a Londra! - mylondoncorner.com" />
        <meta property="og:locale" content="it_IT" />
        <meta property="og:type" content="website" />

        <meta name="keywords" content="tour di Londra, tour a Londra, guida italiana a Londra, visite guidate a Londra, tour esclusivi a Londra, attrazioni a Londra, tour privati a Londra, cosa vedere a Londra, tour in italiano, tour Harry Potter a Londra, attrazioni iconiche a Londra, tour autentici Londra, Cotswolds, tour inediti a Londra, migliori tour a Londra, il meglio di Londra" />
        </Helmet>
      <Navbar />
      <br /><br />
      <div className='tickets-container'>
        <p style={{ fontWeight: 'bold', color: '#00247D' }}>Prenota qui i tuoi</p>
        <h1 style={{ color: '#00247D' }}>Tour di Londra in italiano</h1>
        <br /><br />
        {loadingTours ? (
          <div className="loading-animation"></div>
        ) : (
          <>
            {toursData
            .filter(item => item.visible === true)
            .map((tour, index) => (
              <>
                <div className='our-tours-tour-container' onClick={() => window.location.href = process.env.REACT_APP_BASE_URL + '/tour/' + tour.slug} key={index}>
                  <img src={'https://api.mylondoncorner.com/' + tour.images_paths[0]} alt="Tour" />
                  <div className='our-tours-tour-details'>
                    <p>{tour.minimumAge === 0 ? 'Adatto a tutte le età' : 'Da ' + tour.minimumAge + ' anni'} • {tour.duration}</p>
                    <h2>{tour.name}</h2>
                    <br />
                    <p>{tour.short_description}</p>
                    <br />
                    <p>🏷️ {tour.car_base_price ? 'Da £' + tour.car_base_price + ' / macchina' : tour.price_child != 0 ? 'Da £' + tour.price_child + ' / persona' : tour.price != 0 ? 'Da £' + tour.price + ' / persona' : 'GRATIS' } • Tour in italiano</p>
                    <br />
                    <button className='button'>Prenota ora</button>
                  </div>
                </div>
                <br />
                <hr />
                <br /><br />
              </>
            ))}
            <TourAvailabilityCalendar />
            <br />
          </>
        )}
      </div>
      <Footer />
    </>
  );
}

export default OurTours;