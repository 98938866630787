import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';

// Auth
import { useAuth } from '../../components/AuthContext';

// Components
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/Footer';
import FeedbackPopup from '../../components/widgets/FeedbackPopup';
import SliderImages from '../../components/widgets/SliderImages';
import Calendar from '../../components/tour/Calendar';
import NotFound from '../../components/NotFound';

// Icons
import { IonIcon } from '@ionic/react';
import { flagOutline, timeOutline, checkmark, stopwatchOutline, mapOutline, closeOutline } from 'ionicons/icons';

// Styles
import '../../styles/tour/tour.css';

function Tour() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { slug } = useParams(); // Get the slug from URL parameters

  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [tourData, setTourData] = useState([]);
  const [loadingTour, setLoadingTour] = useState(true);

  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);

  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [pickup, setPickup] = useState('');
  const [guide, setGuide] = useState('0');
  const [requests, setRequests] = useState('');
  const [totalCars, setTotalCars] = useState();

  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    setLoadingTour(true);
    axios.get(`https://api.mylondoncorner.com/tour/${slug}`, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY // Include the API key in the request headers
        }
      })
      .then((response) => {
        setTourData(response.data);
        setLoadingTour(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoadingTour(false);
      });
  }, [slug]);

  useEffect(() => {
    setTotalCars(parseInt((((parseInt(adults) + parseInt(children) + (guide.toLowerCase() === "0" ? 0 : 1 ))) / 9) + 1))
  }, [adults, children, guide])

  const handleAdultsChange = (event) => {
    const selectedAdults = parseInt(event.target.value);
    setAdults(selectedAdults);
  };

  const handleChildrenChange = (event) => {
    const selectedChildren = parseInt(event.target.value);
    setChildren(selectedChildren);
  };

  const handleNameChange = (event) => {
    const selectedName = event.target.value;
    setName(selectedName);
  };

  const handleSurnameChange = (event) => {
    const selectedSurname = event.target.value;
    setSurname(selectedSurname);
  };

  const handleEmailChange = (event) => {
    const selectedEmail = event.target.value;
    setEmail(selectedEmail);
  };

  const handlePhoneChange = (event) => {
    const selectedPhone = event.target.value;
    setPhone(selectedPhone);
  };

  const handlePickupChange = (event) => {
    const selectedPickup = event.target.value;
    setPickup(selectedPickup);
  };

  const handleGuideChange = (event) => {
    const selectedGuide = event.target.value;
    setGuide(selectedGuide);
  };

  const handleRequestsChange = (event) => {
    const selectedRequests = event.target.value;
    setRequests(selectedRequests);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleCarTourRequest = () => {
    if(name != '' && surname != '' && email.includes('@') && selectedDate != null && phone != '' && pickup != '') {
      let date = new Date(selectedDate.target.value)
      const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
      axios.post('https://api.mylondoncorner.com/contact', {
        subject: tourData.tour.name,
        name: name,
        email: email,
        message: `Tour: ${tourData.tour.name}, Date: ${formattedDate}, Phone number: ${phone}, Pickup: ${pickup}, Requests: ${requests}, Guide: ${guide}`
      }, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY
        }
      })
      .then((response) => {
        if(response.status === 200) {
          navigate('/contact/received')
        }
        else {
          setFeedbackMessage('Error occurred while processing the request.');
          setIsPopupOpen(true);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
    } else {
      let message = '';
      if (!email.includes('@')) {
        message = 'E-mail non valida';
      } else if (name === '') {
        message = 'Inserisci il tuo nome';
      } else if (surname === '') {
        message = 'Inserisci il tuo cognome';
      } else if (selectedDate === null) {
        message = 'Inserisci una data';
      } else if (phone === '') {
        message = 'Inserisci numero di telefono';
      } else if (pickup === '') {
        message = 'Inserisci luogo di pickup';
      }

      setFeedbackMessage(message);
      setIsPopupOpen(true);
    }
  }

  const handleAddToCartClick = async () => {
    try {
      if(user) {
        if((adults + children) > 1) {
          if(selectedDate) {
            navigate('/basket'); //Keep it here otherwise not working
            // Format the selected date as "DD/MM/YYYY"
            const formattedDate = `${selectedDate.getDate()}/${selectedDate.getMonth() + 1}/${selectedDate.getFullYear()}`;
            // Separate the axios configuration and the request payload
            await axios.post('https://api.mylondoncorner.com/basket/tour/add', {
              user: user,
              tourId: tourData.tour._id,
              adults: adults,
              children: children,
              selectedDate: formattedDate,
              name: name,
              surname: surname,
              email: email,
              phone: phone,
              pickup: pickup,
              guide: guide,
              requests: requests
            }, {
              headers: {
                'X-API-KEY': process.env.REACT_APP_API_KEY // Include the API key in the request headers
              }
            });
          } else {
            setFeedbackMessage('Seleziona una data!');
            setIsPopupOpen(true);
          }
        } else {
          setFeedbackMessage('Il minimo di partecipanti è 2!');
          setIsPopupOpen(true);
        }
      } else {
        navigate(`/login?redirect=${window.location.href.split('https://mylondoncorner.com')[1]}`);
      }
    } catch (err) {
      console.log('Error adding Tour to basket:', err.message);
    }
  };

  return (
    <>
      {loadingTour ? (
        <>
          <Navbar />
          <div className="loading-animation"></div>
          <Footer />
        </>
      ) : (
        tourData.tour != null ? (
          <>
            <Helmet>
              <title>{tourData.tour.name}</title>‍
              <meta name="description" content={tourData.tour.short_description} />  
              <link rel="canonical" href={`https://mylondoncorner.com/tour/${tourData.tour.slug}`} />      
              <meta name="twitter:card" content={tourData.tour.images_paths[0]} />        
              <meta name="twitter:site" content="@mylondoncorner" />        
              <meta name="twitter:creator" content="@mylondoncorner" />        
              <meta name="twitter:title" content={tourData.tour.name} />        
              <meta name="twitter:description" content={tourData.tour.description} />        
              <meta name="twitter:image" content={tourData.tour.images_paths[0]} />        
              <meta property="og:title" content={tourData.tour.name} />        
              <meta property="og:description" content={tourData.tour.description} />        
              <meta property="og:image" content={tourData.tour.images_paths[0]} />
              <meta property="og:url" content={process.env.REACT_APP_BASE_URL} />
              <meta property="og:site_name" content={tourData.tour.name} />
              <meta property="og:locale" content="it_IT" />
              <meta property="og:type" content="website" />

              <meta name="keywords" content="Viaggi a Londra, Turismo a Londra, Attrazioni di Londra, Cose da fare a Londra, Guida di Londra, Vacanza a Londra, Visite turistiche a Londra, Hotel a Londra, Trasporti a Londra, Luoghi famosi di Londra, mylondoncorner" />
            </Helmet>

            <Navbar />
            <div className='tour-image-container-small-screen'>
              <SliderImages images={tourData.tour.images_paths} />
              <br />
              <div className='tour-container'>
                <br />
                <p style={{ color: 'grey', fontWeight: 'bold' }}>{tourData.tour.category[0].name.toUpperCase()}</p>
                <br />
                <h1>{tourData.tour.name}</h1>
                <br /><br />
                <hr />
                <br />
              </div>
            </div>
            <div className='tour-container row'>
              <div className='tour-large-image-header'>
                <p style={{ color: 'grey', fontWeight: 'bold' }}>{tourData.tour.category[0].name.toUpperCase()}</p>
                <br />
                <h1>{tourData.tour.name}</h1>
                <br />
                <div className='row'>
                  <div className='tour-header-column-left'>
                    <img src={'https://api.mylondoncorner.com/' + tourData.tour.images_paths[0]} alt="Tour image description 1" loading="lazy" />
                  </div>
                  <div className='tour-header-column-right row'>
                    <div className='tour-header-column-right'>
                      <img src={'https://api.mylondoncorner.com/' + tourData.tour.images_paths[1]} style={{ paddingLeft: '2%', paddingBottom: '2%' }} alt="Tour image description 2" loading="lazy" />
                    </div>
                    <div className='tour-header-column-right'>
                      <img src={'https://api.mylondoncorner.com/' + tourData.tour.images_paths[2]} style={{ paddingLeft: '2%', paddingBottom: '2%', borderTopRightRadius: '10px' }} alt="Tour image description 3" loading="lazy" />
                    </div>
                    <div className='tour-header-column-right'>
                      <img src={'https://api.mylondoncorner.com/' + tourData.tour.images_paths[3]} style={{ paddingLeft: '2%' }} alt="Tour image description 4" loading="lazy" />
                    </div>
                    <div className='tour-header-column-right'>
                      <img src={'https://api.mylondoncorner.com/' + tourData.tour.images_paths[4]} style={{ paddingLeft: '2%', borderBottomRightRadius: '10px' }} alt="Tour image description 5" loading="lazy" />
                    </div>
                  </div>
                </div>
                <br />
              </div>
              <br />
              <div className='tour-left-column'>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <IonIcon icon={stopwatchOutline} style={{ fontSize: '25px', marginRight: '10px' }} />
                  <div>
                    <b>Durata</b><br />
                    {tourData.tour.duration}
                  </div>
                </div>
                <br />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <IonIcon icon={timeOutline} style={{ fontSize: '25px', marginRight: '10px' }} />
                  <div>
                    <b>Orario di incontro</b><br />
                    {tourData.tour.meeting_time}
                  </div>
                </div>
                <br />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <IonIcon icon={mapOutline} style={{ fontSize: '25px', marginRight: '10px' }} />
                  <div>
                    <b>Luogo di incontro</b><br />
                    {tourData.tour.meeting_point}
                  </div>
                </div>
                <br />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <IonIcon icon={mapOutline} style={{ fontSize: '25px', marginRight: '10px' }} />
                  <div>
                    <b>Luogo di fine del Tour</b><br />
                    {tourData.tour.ending_point}
                  </div>
                </div>
                <br />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <IonIcon icon={flagOutline} style={{ fontSize: '25px', marginRight: '10px' }} />
                  <div>
                    <b>Lingua</b><br />
                    Italiano
                  </div>
                </div>
                <br /><br />
                <hr />
                <br /><br />
                <div dangerouslySetInnerHTML={{ __html: tourData.tour.description }} />
                <br /><br />
                <hr />
                <br /><br />
                {tourData.tour.car_base_price === 0 ? (
                  <div className='tour-quantity-selector' style={{ borderRadius: '10px' }}>
                    <br />
                    <h2>Seleziona numero di partecipanti</h2>
                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '2%', margin: '2%' }}>
                        <label htmlFor="quantity_adult" style={{ marginBottom: '5px', fontSize: '15px', textAlign: 'center' }}>Adulti (16+)</label>
                        <select id="quantity_adult" name="quantity_adult" onChange={handleAdultsChange} style={{ width: '100%', padding: '10px', borderRadius: '10px', fontSize: '15px' }}>
                          {Array.from({ length: tourData.tour.available_places }, (_, index) => (
                            <option key={index} value={index + 1}>
                              {index + 1}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '2%', margin: '2%' }}>
                        <label htmlFor="quantity_children" style={{ marginBottom: '5px', fontSize: '15px', textAlign: 'center' }}>Bambini (5-15)</label>
                        <select id="quantity_children" name="quantity_children" onChange={handleChildrenChange} style={{ width: '100%', padding: '10px', borderRadius: '10px', fontSize: '15px' }}>
                          <option key='0' value='0'>0</option>
                          {Array.from({ length: tourData.tour.available_places }, (_, index) => (
                            <option key={index} value={index + 1}>
                              {index + 1}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <h3 style={{ textAlign: 'center', fontWeight: 'bold' }}>Totale: £{adults * tourData.tour.price + children * tourData.tour.price_child}</h3>
                    {(adults * tourData.tour.price + children * tourData.tour.price_child) > 160 &&
                      <>
                        <br />
                        <a href='https://mylondoncorner.com/private-guides'><button className='button'>Risparmia! Richiedi un Tour privato!</button></a>
                      </>
                    }
                    <br />
                  </div>
                ) : (
                  <div className='tour-quantity-selector' style={{ borderRadius: '10px' }}>
                    <br />
                    <h2>Seleziona numero di partecipanti</h2>
                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '2%', margin: '0 2% 0 2%' }}>
                        <label htmlFor="quantity_adult" style={{ marginBottom: '5px', fontSize: '15px', textAlign: 'center' }}>Adulti (16+)</label>
                        <select id="quantity_adult" name="quantity_adult" onChange={handleAdultsChange} style={{ width: '100%', padding: '10px', borderRadius: '10px', fontSize: '15px' }}>
                          {Array.from({ length: tourData.tour.available_places }, (_, index) => (
                            <option key={index} value={index + 1}>
                              {index + 1}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '2%', margin: '0 2% 0 2%' }}>
                        <label htmlFor="quantity_children" style={{ marginBottom: '5px', fontSize: '15px', textAlign: 'center' }}>Bambini (5-15)</label>
                        <select id="quantity_children" name="quantity_children" onChange={handleChildrenChange} style={{ width: '100%', padding: '10px', borderRadius: '10px', fontSize: '15px' }}>
                          <option key='0' value='0'>0</option>
                          {Array.from({ length: tourData.tour.available_places }, (_, index) => (
                            <option key={index} value={index + 1}>
                              {index + 1}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <br />
                    <h2>Informazioni aggiuntive</h2>
                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '2%', margin: '0 2% 0 2%' }}>
                        <label htmlFor="name" style={{ marginBottom: '5px', fontSize: '15px', textAlign: 'center' }}>Nome</label>
                        <input type='text' id="name" name="name" onChange={handleNameChange} style={{ width: '100%', padding: '10px', borderRadius: '10px', fontSize: '15px' }} />
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '2%', margin: '0 2% 0 2%' }}>
                        <label htmlFor="surname" style={{ marginBottom: '5px', fontSize: '15px', textAlign: 'center' }}>Cognome</label>
                        <input type='text' id="surname" name="surname" onChange={handleSurnameChange} style={{ width: '100%', padding: '10px', borderRadius: '10px', fontSize: '15px' }} />
                      </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '2%', margin: '0 2% 0 2%' }}>
                        <label htmlFor="email" style={{ marginBottom: '5px', fontSize: '15px', textAlign: 'center' }}>E-mail</label>
                        <input type='email' id="email" name="email" onChange={handleEmailChange} style={{ width: '100%', padding: '10px', borderRadius: '10px', fontSize: '15px' }} />
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '2%', margin: '0 2% 0 2%' }}>
                        <label htmlFor="phone" style={{ marginBottom: '5px', fontSize: '15px', textAlign: 'center' }}>Telefono</label>
                        <input type='text' id="phone" name="phone" onChange={handlePhoneChange} style={{ width: '100%', padding: '10px', borderRadius: '10px', fontSize: '15px' }} />
                      </div>
                    </div>
                    <br />
                    <h2>Il tuo Tour</h2>
                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '2%', margin: '0 2% 0 2%' }}>
                        <label htmlFor="date" style={{ marginBottom: '5px', fontSize: '15px', textAlign: 'center' }}>Data richiesta</label>
                        <input type='date' id="date" name="date" onChange={handleDateChange} style={{ width: '100%', padding: '10px', borderRadius: '10px', fontSize: '15px' }} />
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '2%', margin: '0 2% 0 2%' }}>
                        <label htmlFor="pickup" style={{ marginBottom: '5px', fontSize: '15px', textAlign: 'center' }}>Alloggio per il pick-up</label>
                        <input type='text' id="pickup" name="pickup" onChange={handlePickupChange} style={{ width: '100%', padding: '10px', borderRadius: '10px', fontSize: '15px' }} />
                      </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '2%', margin: '0 2% 0 2%' }}>
                        <label htmlFor="guide" style={{ marginBottom: '5px', fontSize: '15px', textAlign: 'center' }}>Aggiungere guida in italiano?</label>
                        <select id="guide" name="guide" onChange={handleGuideChange} style={{ width: '100%', padding: '10px', borderRadius: '10px', fontSize: '15px' }}>
                          <option key='yes' value='0'>No</option>
                          <option key='no' value='1'>Si</option>
                        </select>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '2%', margin: '0 2% 0 2%' }} />
                    </div>
                    <br />
                    <h2>Richieste particolari</h2>
                    <div style={{ padding: '2%', margin: '0 2% 0 2%' }}>
                        <textarea id="requests" name="requests" onChange={handleRequestsChange} rows={8} placeholder='Qualche domanda? Richiesta particolare?' style={{ width: '100%', padding: '10px', borderRadius: '10px', fontSize: '15px' }} />
                    </div>
                    <br />
                    <h2>
                      Totale: {totalCars > 1 ? totalCars + ' macchine' : totalCars + ' macchina'} {guide.toLowerCase() === "0" ? ' senza guida' : ' con guida'}, £{tourData.tour.car_base_price * totalCars + (guide.toLowerCase() === "0" ? 0 : tourData.tour.car_price_guide)}
                    </h2>
                    <br />
                    <button className='button' onClick={handleCarTourRequest}>Richiedi</button>
                    <br />
                  </div>
                )}
                {!tourData.tour.car_base_price && (
                  <>
                    <br /><br />
                    <Calendar 
                      selectableDates={tourData.tour.available_dates.split(",").map(dateString => {
                        let parts = dateString.split("/");
                        // Format the date parts to MM/DD/YYYY
                        let formattedDate = `${parts[1]}/${parts[0].padStart(2, '0')}/${parts[2]}`;
                        return new Date(formattedDate);
                      })} 
                      onDateChange={handleDateChange} 
                    />
                    <br /><br />
                    <button className='button' onClick={handleAddToCartClick}>Aggiungi al carrello</button>
                  </>
                )}
                <br /><br />
                <hr />
                <br /><br />
                <h3>In evidenza</h3>
                <br />
                {tourData.tour.highlights.split("•").slice(1).map(element => (
                  <p key={element}>• {element.trim()}</p>
                ))}
                <br /><br />
                <h3>Cosa è incluso</h3>
                <br />
                {tourData.tour.what_included.split("•").slice(1).map(element => (
                  <p key={element}><IonIcon icon={checkmark} style={{ color: 'green', fontSize: '20px' }} /> {element.trim()}</p>
                ))}
                {tourData.tour.what_not_included.split("•").slice(1).map(element => (
                  <p key={element}><IonIcon icon={closeOutline} style={{ color: 'red', fontSize: '20px' }} /> {element.trim()}</p>
                ))}
                <br /><br />
                <h3>Cosa portare</h3>
                <br />
                {tourData.tour.what_to_bring.split("•").slice(1).map(element => (
                  <p key={element}>• {element.trim()}</p>
                ))}
                <br />
              </div>

              <div className='tour-right-column'>
                <div className='tour-price-box'>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h3>A partire da:</h3>
                    {tourData.tour.car_base_price ? (
                        <h3>£{tourData.tour.car_base_price} a macchina (8)</h3>
                      ) : (
                        <h3>£{tourData.tour.price_child} a persona</h3>
                      )
                    }
                  </div>
                  <br />
                  <p>Tutte le tasse e le spese incluse</p>
                </div>
              </div>
            </div>
            <Footer />
            <FeedbackPopup
              isOpen={isPopupOpen}
              message={feedbackMessage}
              onClose={() => setIsPopupOpen(false)}
              onConfirm={() => setIsPopupOpen(false)}
              confirmButtonText="OK"
            />
          </>
        ) : (
          <NotFound />
        )
      )}
    </>
  )
}

export default Tour;